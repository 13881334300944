import HomepageItemFullWidth from "../components/Homepage/HomepageItemFullWidth";
import {homepageItems} from "../components/Homepage/homepageItems";

// This is the component for the Homepage.
// It renders the content of homepageItems.js in separate sub-components.

const Homepage = () => {
    return (
        <div data-testid={"homepageOverall"}>
            {homepageItems.map((service, index) => (
                <div key={index}>
                    <HomepageItemFullWidth title={service.title} description={service.description} icon={service.icon}
                                           image={service.image} link={service.link} index={index} key={service.id}/>
                </div>
            ))}
        </div>
    );
}

export default Homepage;