import React from 'react';
import {Box, Typography, Button} from '@mui/material';
import {Link} from 'react-router-dom';

const NotFound = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
            }}
        >
            <Typography variant="h1" component="h1" color="primary" fontWeight={500}>
                404
            </Typography>
            <Typography variant="h6" component="p" color="textSecondary" gutterBottom>
                Oops! The page you're looking for doesn't exist.
            </Typography>
            <Button
                component={Link}
                to="/"
                variant="contained"
                color="primary"
                sx={{marginTop: 2}}
            >
                Go Back Home
            </Button>
        </Box>
    );
};

export default NotFound;
