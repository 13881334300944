import {useTheme} from "@mui/material/styles";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

const FooterContent = () => {
    // const theme = useTheme();

    return (
        <Container maxWidth={'xl'}>
            <Grid container spacing={5} py={3}
                  justifyItems={'center'}>
                <Grid item xs={12} md={4}>
                    Grid item xs={12} md={4}
                </Grid>

                <Grid item xs={12} md={4}
                      sx={{
                          justifyItems: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                      }}
                >
                    Grid item xs={12} md={4}
                </Grid>

                <Grid item xs={12} md={4} sx={{
                    justifyItems: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                    Grid item xs={12} md={4}
                </Grid>
            </Grid>
        </Container>
    );
}

export default FooterContent;