import Keycloak from "keycloak-js";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// This is the file for the Keycloak integration.                                                                      //
// It is good practice to have two configurations to be able to test both the local and the deployed versions.         //
// Push the deployment configuration on the VM - comment it and uncomment the local configuration for local testing.   //
//                                                                                                                     //
// The configuration requires the Realm, the Keycloak deployment link and the client ID.                               //
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Localhost deployment configuration
// const my_keycloak = new Keycloak({
//     "realm": "EnergyHomeSystem",
//     "url": "https://keycloak.buildon.epu.ntua.gr/",
//     "clientId": "EnergyHomeSystem_dev"
// })

// Server deployment configuration
const my_keycloak = new Keycloak({
    "realm": "EnergyHomeSystem",
    "url": "https://keycloak.buildon.epu.ntua.gr/",
    "clientId": "aggregator"
})

export default my_keycloak