import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, Container } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsMore from 'highcharts/highcharts-more';
import CurrentTime from "../Layout/CurrentTime";
import InfoIcon from '@mui/icons-material/Info';
import {useKeycloak} from "@react-keycloak/web";
import {useNavigate} from "react-router-dom";

// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsMore(Highcharts); // Required for `arearange`

// Chart Titles and Subtitles
const chartDetails = {
    consumption: {
        title: "Electricity Consumption Forecast",
        subtitle: "This chart predicts future electricity consumption based on recent data.",
    },
    production: {
        title: "Electricity Production Forecast",
        subtitle: "This chart predicts future electricity production based on recent data.",
    },
};

// Generate random actual data for the last 3 hours (5-minute intervals)
const generateActualData = (baseValue) => {
    const currentTime = moment(); // Local current time
    const startTime = moment().subtract(1, 'hours'); // Start 1 hour ago
    const data = [];
    const categories = [];

    while (startTime.isBefore(currentTime) || startTime.isSame(currentTime, 'minute')) {
        categories.push(startTime.format('HH:mm'));
        data.push(Math.max(0, baseValue + Math.random() * 10 - 5)); // Generate non-negative values
        startTime.add(5, 'minutes'); // Increment by 5 minutes
    }

    return { data, categories };
};

// Generate forecast data (3 hours, 5-minute intervals) based on the last actual value
const generateForecastData = (lastActualValue) => {
    const forecast = [];
    const forecastPlusError = [];
    const forecastMinusError = [];
    const forecastRange = [];
    const forecastCategories = [];
    const currentTime = moment();
    const forecastEndTime = moment().add(3, 'hours');
    let errorMargin = 5;

    while (currentTime.isBefore(forecastEndTime)) {
        const baseValue = Math.max(0, lastActualValue + (Math.random() * 10 - 5)); // Ensure non-negative values
        forecast.push(baseValue);
        forecastPlusError.push(baseValue + errorMargin);
        forecastMinusError.push(Math.max(0, baseValue - errorMargin)); // No negative values
        forecastRange.push([currentTime.valueOf(), Math.max(0, baseValue - errorMargin), baseValue + errorMargin]);
        forecastCategories.push(currentTime.format('HH:mm'));
        currentTime.add(5, 'minutes');
        errorMargin += 0.5; // Increment error margin for future predictions
    }

    return { forecast, forecastPlusError, forecastMinusError, forecastRange, forecastCategories };
};

// Create chart options
const createChartOptions = (
    title,
    actual = [],
    forecast = { forecast: [], forecastCategories: [], forecastRange: [], forecastPlusError: [], forecastMinusError: [] },
    actualCategories = [],
    forecastCategories = [],
    currentTime
) => ({
    chart: {
        type: 'line',
        height: 400,
        zooming: {
            type: 'x',
        },
    },
    time: {
        useUTC: false, // Disable UTC to use the browser's local timezone
    },
    title: {
        text: '',
        align: 'left',
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#4B5563',
        },
    },
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: true,
        buttons: {
            contextButton: {
                menuItems: [
                    'viewFullscreen',
                    'downloadPNG',
                    'downloadJPEG',
                    'downloadPDF',
                    'downloadSVG',
                    'separator',
                    'downloadCSV',
                    'downloadXLS',
                ],
            },
        },
    },
    xAxis: {
        type: 'datetime',
        plotLines: [
            {
                color: '#4840d6',
                width: 2,
                value: currentTime || moment().valueOf(),
                zIndex: 2,
                dashStyle: 'Dash',
                label: {
                    text: 'Current time',
                    rotation: 0,
                    y: 20,
                    style: {
                        color: '#333333',
                    },
                },
            },
        ],
    },
    yAxis: {
        title: {
            text: '',
        },
        gridLineColor: '#E5E7EB',
    },
    tooltip: {
        valueSuffix: ' kWh',
    },
    plotOptions: {
        line: {
            marker: {
                enabled: false,
            },
        },
        series: {
            states: {
                hover: {
                    lineWidth: 2,
                },
            },
        },
    },
    series: [
        {
            name: 'Forecast Error Range',
            data: forecast.forecastRange || [],
            type: 'arearange',
            color: 'rgba(255, 167, 38, 0.6)', // Semi-transparent orange fill
            fillOpacity: 0.6,
            zIndex: 0,
            enableMouseTracking: false,
            marker: {
                enabled: false,
            },
            showInLegend: false, // Hide this line in the legend
        },
        {
            name: 'Actual',
            data: actual.length && actualCategories.length
                ? actual.map((value, index) => [
                    moment()
                        .subtract(1, 'hours')
                        .add(index * 5, 'minutes')
                        .valueOf(),
                    value,
                ])
                : [],
            type: 'area',
            lineWidth: 2,
            color: '#161F6D', // Primary color for actual line
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [0, '#161F6D'],
                    [1, '#00ABE1'],
                ],
            },
            fillOpacity: 0.5,
            marker: {
                enabled: false,
            },
        },
        {
            name: 'Forecast + Error',
            data: forecast.forecastPlusError?.map((value, index) => [
                moment().add(index * 5, 'minutes').valueOf(),
                value,
            ]) || [],
            type: 'line',
            color: '#FFA726',
            lineWidth: 1,
            dashStyle: 'ShortDash',
            marker: {
                enabled: false,
            },
            showInLegend: false, // Hide this line in the legend
        },
        {
            name: 'Forecast',
            data: forecast.forecast?.map((value, index) => [
                moment().add(index * 5, 'minutes').valueOf(),
                value,
            ]) || [],
            type: 'line',
            // dashStyle: 'Dot', // Regular dotted line
            color: '#FF6F61',
            lineWidth: 2,
            marker: {
                enabled: false, // Disable markers for the forecast line
            },
            showInLegend: true, // Hide this line in the legend
        },
        {
            name: 'Forecast - Error',
            data: forecast.forecastMinusError?.map((value, index) => [
                moment().add(index * 5, 'minutes').valueOf(),
                value,
            ]) || [],
            type: 'line',
            color: '#FFA726',
            lineWidth: 1,
            dashStyle: 'ShortDash',
            marker: {
                enabled: false,
            },
            showInLegend: false, // Hide this line in the legend
        },
    ],
    legend: {
        enabled: true,
    },
});


const Forecasts = () => {
    const {keycloak, initialized} = useKeycloak()
    const [allowed, setAllowed] = useState(false)
    const navigate = useNavigate();

    const [consumptionData, setConsumptionData] = useState([]);
    const [productionData, setProductionData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [consumptionForecast, setConsumptionForecast] = useState({
        forecast: [],
        forecastCategories: [],
        forecastRange: [],
    });
    const [productionForecast, setProductionForecast] = useState({
        forecast: [],
        forecastCategories: [],
        forecastRange: [],
    });
    const [currentTime, setCurrentTime] = useState(moment().valueOf());

    useEffect(() => {
        if (!initialized) return; // Wait until Keycloak is initialized

        if (keycloak.authenticated) {
            setAllowed(true); // User is authenticated, allow access
        } else {
            navigate('/'); // User is not authenticated, redirect to homepage
        }
    }, [keycloak.authenticated, initialized, navigate]);

    useEffect(() => {
        const consumptionActual = generateActualData(50);
        const productionActual = generateActualData(30);

        const consumptionForecastData = generateForecastData(consumptionActual.data[consumptionActual.data.length - 1]);
        const productionForecastData = generateForecastData(productionActual.data[productionActual.data.length - 1]);

        setConsumptionData(consumptionActual.data);
        setProductionData(productionActual.data);
        setCategories(consumptionActual.categories);
        setConsumptionForecast(consumptionForecastData);
        setProductionForecast(productionForecastData);
        setCurrentTime(moment().valueOf());
    }, []);

    const consumptionOptions = createChartOptions(
        chartDetails.consumption.title,
        consumptionData,
        consumptionForecast,
        categories,
        currentTime
    );

    const productionOptions = createChartOptions(
        chartDetails.production.title,
        productionData,
        productionForecast,
        categories,
        currentTime
    );

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '16px',
        width: '100%',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    };

    return (
        <>
            {allowed && <Box sx={{padding: 3}}>
                <CurrentTime/>

                <Container maxWidth="xl" sx={{padding: 3}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper style={boxStyle}>
                                <Typography variant="h6" style={{fontWeight: 'bold', textAlign: 'center'}}>
                                    {chartDetails.consumption.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{
                                        textAlign: 'center',
                                        marginBottom: '10px',
                                        color: '#6B7280',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <InfoIcon sx={{fontSize: '16px', marginRight: '5px', color: '#161F6D'}}/>
                                    {chartDetails.consumption.subtitle}
                                </Typography>
                                <HighchartsReact highcharts={Highcharts} options={consumptionOptions}/>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper style={boxStyle}>
                                <Typography variant="h6" style={{fontWeight: 'bold', textAlign: 'center'}}>
                                    {chartDetails.production.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{
                                        textAlign: 'center',
                                        marginBottom: '10px',
                                        color: '#6B7280',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <InfoIcon sx={{fontSize: '16px', marginRight: '5px', color: '#161F6D'}}/>
                                    {chartDetails.production.subtitle}
                                </Typography>
                                <HighchartsReact highcharts={Highcharts} options={productionOptions}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>}
        </>
    );
};

export default Forecasts;
