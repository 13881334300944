import React, { useState, useEffect } from 'react';
import { Box, Paper, Grid, Button, Typography, Skeleton, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import axios from 'axios';
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from 'react-router-dom';
import CurrentTime from "../Layout/CurrentTime";

const createChartOptions = (title, categories, data, avg, palette) => ({
    chart: {
        type: 'column',
        height: 300,
    },
    credits: {
        enabled: false,
    },
    title: {
        text: title,
        align: 'center',
        style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#4B5563',
        },
    },
    xAxis: {
        categories: categories,
        lineColor: '#E5E7EB',
        tickColor: '#E5E7EB',
        labels: {
            style: { fontSize: '12px', color: '#6B7280' },
        },
    },
    yAxis: {
        title: {
            text: null,
        },
        gridLineColor: '#E5E7EB',
    },
    tooltip: {
        valueSuffix: ' kWh',
    },
    series: [
        {
            data: data.map((value) => ({
                y: value,
                color:
                    value < 0.9 * avg
                        ? palette.secondary.main
                        : value <= 1.1 * avg
                            ? palette.middle.main
                            : palette.primary.main,
            })),
            borderRadius: 3,
        },
    ],
    legend: {
        enabled: false,
    },
});

const HistoricalStatistics = () => {
    const { keycloak, initialized } = useKeycloak();
    const [allowed, setAllowed] = useState(false);
    const navigate = useNavigate();

    const theme = useTheme();
    const [consumptionData, setConsumptionData] = useState({ lastDay: [], lastWeek: [] });
    const [consumptionCategories, setConsumptionCategories] = useState({ lastDay: [], lastWeek: [] });
    const [productionData, setProductionData] = useState({ lastDay: [], lastWeek: [] });
    const [productionCategories, setProductionCategories] = useState({ lastDay: [], lastWeek: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [consumptionTab, setConsumptionTab] = useState('Last 24 Hours');
    const [productionTab, setProductionTab] = useState('Last 24 Hours');

    useEffect(() => {
        if (!initialized) return;

        if (keycloak.authenticated) {
            setAllowed(true);
        } else {
            navigate('/');
        }
    }, [keycloak.authenticated, initialized, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(false); // Reset error state
            try {
                const [consumptionLastDay, consumptionLastWeek, productionLastDay, productionLastWeek] = await Promise.all([
                    axios.get('/aggregator/consumption/lastday'),
                    axios.get('/aggregator/consumption/lastweek'),
                    axios.get('/aggregator/production/lastday'),
                    axios.get('/aggregator/production/lastweek'),
                ]);
                setConsumptionData({
                    lastDay: consumptionLastDay.data.values,
                    lastWeek: consumptionLastWeek.data.values,
                });
                setConsumptionCategories({
                    lastDay: consumptionLastDay.data.labels,
                    lastWeek: consumptionLastWeek.data.labels,
                });
                setProductionData({
                    lastDay: productionLastDay.data.values,
                    lastWeek: productionLastWeek.data.values,
                });
                setProductionCategories({
                    lastDay: productionLastDay.data.labels,
                    lastWeek: productionLastWeek.data.labels,
                });
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const consumptionValues =
        consumptionTab === 'Last 24 Hours'
            ? consumptionData.lastDay
            : consumptionData.lastWeek;
    const consumptionLabels =
        consumptionTab === 'Last 24 Hours'
            ? consumptionCategories.lastDay
            : consumptionCategories.lastWeek;

    const productionValues =
        productionTab === 'Last 24 Hours'
            ? productionData.lastDay
            : productionData.lastWeek;
    const productionLabels =
        productionTab === 'Last 24 Hours'
            ? productionCategories.lastDay
            : productionCategories.lastWeek;

    const avgConsumption =
        consumptionValues.reduce((a, b) => a + b, 0) / consumptionValues.length || 0;

    const avgProduction =
        productionValues.reduce((a, b) => a + b, 0) / productionValues.length || 0;

    const consumptionChartOptions = createChartOptions(
        consumptionTab,
        consumptionLabels,
        consumptionValues,
        avgConsumption,
        theme.palette
    );

    const productionChartOptions = createChartOptions(
        productionTab,
        productionLabels,
        productionValues,
        avgProduction,
        theme.palette
    );

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '16px',
        width: '100%',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    };

    const smallBoxStyle = {
        padding: '16px',
        borderRadius: '12px',
        backgroundColor: theme.palette.middle.main,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        color: '#fff',
    };

    const iconStyle = {
        fontSize: '36px',
        color: '#fff',
    };

    return (
        <>
            {allowed && <Box sx={{ padding: 3 }}>
                <CurrentTime />

                <Grid container spacing={3}>
                    {/* Consumption Section */}
                    <Grid item xs={12} md={6}>
                        <Paper style={boxStyle}>
                            <Typography
                                variant="h6"
                                style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}
                            >
                                Consumption Charts
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
                                <Button
                                    variant={consumptionTab === 'Last 24 Hours' ? 'contained' : 'outlined'}
                                    onClick={() => setConsumptionTab('Last 24 Hours')}
                                    sx={{
                                        color: consumptionTab === 'Last 24 Hours' ? '#fff' : '#000',
                                    }}
                                >
                                    Last 24 Hours
                                </Button>
                                <Button
                                    variant={consumptionTab === 'Last 7 Days' ? 'contained' : 'outlined'}
                                    onClick={() => setConsumptionTab('Last 7 Days')}
                                    sx={{
                                        color: consumptionTab === 'Last 7 Days' ? '#fff' : '#000',
                                    }}
                                >
                                    Last 7 Days
                                </Button>
                            </Box>
                            {loading ? (
                                <Skeleton variant="rectangular" height={300} />
                            ) : error ? (
                                <Alert severity="warning" style={{ textAlign: 'center', marginTop: '20px' }}>
                                    Failed to load consumption data. Please try again later.
                                </Alert>
                            ) : (
                                <HighchartsReact highcharts={Highcharts} options={consumptionChartOptions} />
                            )}
                            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                {loading ? (
                                    <>
                                        <Grid item xs={6}>
                                            <Skeleton variant="rectangular" height={80} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Skeleton variant="rectangular" height={80} />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={6}>
                                            {!error && <Box style={smallBoxStyle}>
                                                <ShowChartIcon style={iconStyle}/>
                                                <Box>
                                                    <Typography variant="h5"
                                                                style={{fontWeight: 'bold', color: '#fff'}}>
                                                        {avgConsumption.toFixed(2)} kWh
                                                    </Typography>
                                                    <Typography variant="body2" style={{color: '#fff'}}>
                                                        Avg Consumption
                                                    </Typography>
                                                </Box>
                                            </Box>}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!error && <Box style={smallBoxStyle}>
                                                <BarChartIcon style={iconStyle}/>
                                                <Box>
                                                    <Typography variant="h5"
                                                                style={{fontWeight: 'bold', color: '#fff'}}>
                                                        {Math.max(...consumptionValues).toFixed(2)} kWh
                                                    </Typography>
                                                    <Typography variant="body2" style={{color: '#fff'}}>
                                                        Max Consumption
                                                    </Typography>
                                                </Box>
                                            </Box>}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Production Section */}
                    <Grid item xs={12} md={6}>
                        <Paper style={boxStyle}>
                            <Typography
                                variant="h6"
                                style={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}
                            >
                                Production Charts
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
                                <Button
                                    variant={productionTab === 'Last 24 Hours' ? 'contained' : 'outlined'}
                                    onClick={() => setProductionTab('Last 24 Hours')}
                                    sx={{
                                        color: productionTab === 'Last 24 Hours' ? '#fff' : '#000',
                                    }}
                                >
                                    Last 24 Hours
                                </Button>
                                <Button
                                    variant={productionTab === 'Last 7 Days' ? 'contained' : 'outlined'}
                                    onClick={() => setProductionTab('Last 7 Days')}
                                    sx={{
                                        color: productionTab === 'Last 7 Days' ? '#fff' : '#000',
                                    }}
                                >
                                    Last 7 Days
                                </Button>
                            </Box>
                            {loading ? (
                                <Skeleton variant="rectangular" height={300} />
                            ) : error ? (
                                <Alert severity="warning" style={{ textAlign: 'center', marginTop: '20px' }}>
                                    Failed to load production data. Please try again later.
                                </Alert>
                            ) : (
                                <HighchartsReact highcharts={Highcharts} options={productionChartOptions} />
                            )}
                            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                {loading ? (
                                    <>
                                        <Grid item xs={6}>
                                            <Skeleton variant="rectangular" height={80} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Skeleton variant="rectangular" height={80} />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        {!error && <Grid item xs={6}>
                                            <Box style={smallBoxStyle}>
                                                <ShowChartIcon style={iconStyle}/>
                                                <Box>
                                                    <Typography variant="h5"
                                                                style={{fontWeight: 'bold', color: '#fff'}}>
                                                        {avgProduction.toFixed(2)} kWh
                                                    </Typography>
                                                    <Typography variant="body2" style={{color: '#fff'}}>
                                                        Avg Production
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>}
                                        <Grid item xs={6}>
                                            {!error && <Box style={smallBoxStyle}>
                                                <BarChartIcon style={iconStyle}/>
                                                <Box>
                                                    <Typography variant="h5"
                                                                style={{fontWeight: 'bold', color: '#fff'}}>
                                                        {Math.max(...productionValues).toFixed(2)} kWh
                                                    </Typography>
                                                    <Typography variant="body2" style={{color: '#fff'}}>
                                                        Max Production
                                                    </Typography>
                                                </Box>
                                            </Box>}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>}
        </>
    );
};

export default HistoricalStatistics;
