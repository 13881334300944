import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

// Styling for the time box
const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '16px',
    width: '100%',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
};

const CurrentTime = () => {
    const [currentTime, setCurrentTime] = useState(moment().format('dddd D MMMM HH:mm'));

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment().format('dddd D MMMM HH:mm'));
        }, 60000); // Update every minute

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    return (
        <Box style={{ ...boxStyle, height: '50px', marginBottom: '20px' }}>
            <Typography
                variant="subtitle1"
                style={{
                    fontWeight: 500,
                }}
            >
                <span style={{ fontWeight: 500 }}>Date and Time</span>
                <span style={{ fontWeight: 400 }}>: {currentTime}</span>
            </Typography>
        </Box>
    );
};

export default CurrentTime;
