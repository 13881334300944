import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import TimelineIcon from '@mui/icons-material/Timeline';
import GroupIcon from '@mui/icons-material/Group';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BarChartIcon from '@mui/icons-material/BarChart';


//////////////////////////////////////////////////////////////////////////////////////
// Here you can have a list of the functionalities to be displayed in the homepage. //
// You can change all the fields with the desired content.                          //
//////////////////////////////////////////////////////////////////////////////////////

export const homepageItems = [
    {
        'id': '1',
        'title': 'Real-Time Monitoring',
        'description': 'Monitor live energy demand, production, and net energy balance across the network in real-time.',
        'icon': <ViewTimelineIcon style={{color: 'white', fontSize: '80px'}}/>,
        'image': 'https://empmonitor.com/blog/wp-content/uploads/2024/04/what-are-the-benefits-of-real-time-monitoring-1024x576.webp',
        // 'link': '/real-time-monitoring'
    },
    {
        'id': '2',
        'title': 'Historical Statistics',
        'description': 'View detailed consumption and production statistics for the last 24 hours or 7 days.',
        'icon': <SettingsSuggestIcon style={{color: 'white', fontSize: '80px'}}/>,
        'image': 'https://inycomenergy.com/wp-content/uploads/2021/03/foto_gestiondelaenergia_dataenergyanalitics_3.jpg.jpg',
        // 'link': '/historical-statistics'
    },
    {
        'id': '3',
        'title': 'Energy Forecast',
        'description': 'Access future energy demand and production forecasts with error ranges for informed decisions.',
        'icon': <TimelineIcon style={{color: 'white', fontSize: '80px'}}/>,
        'image': 'https://trellis.net/wp-content/uploads/2024/07/solartechmetamorworkssstock.png?w=1024',
        // 'link': '/energy-forecast'
    },
    {
        'id': '4',
        'title': 'User Management',
        'description': 'Track and manage users categorized as Consumers, Prosumers, and Community members.',
        'icon': <GroupIcon style={{color: 'white', fontSize: '80px'}}/>,
        'image': 'https://www.loginradius.com/blog/static/3d1a7f9993b6334444b52ae84a06f852/d3746/user-mngmnt.jpg',
        // 'link': '/user-management'
    },
    {
        'id': '5',
        'title': 'Energy Community Management',
        'description': 'Oversee and manage the collaboration between energy communities to optimize resources and share benefits.',
        'icon': <PeopleOutlineIcon style={{color: 'white', fontSize: '80px'}}/>,
        'image': 'https://bankwatch.org/wp-content/uploads/2023/05/WWW-Use-for-webpage-covers-blog-post-publications-PRs-2023-06-05T103138.943.png',
        // 'link': '/energy-community-management'
    },
    {
        'id': '6',
        'title': 'Energy Market Analysis',
        'description': 'Analyze market trends, pricing, and trading opportunities to improve energy strategy and profitability.',
        'icon': <BarChartIcon style={{color: 'white', fontSize: '80px'}}/>,
        'image': 'https://www.acer.europa.eu/sites/default/files/styles/main_images_news_and_pages/public/2024-11/Cover_medium.jpg?itok=YxxBuc0y',
        // 'link': '/energy-market-analysis'
    }
];