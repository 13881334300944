import React, {useState, useEffect} from 'react';
import {Box, Paper, Grid, Container, Skeleton, Alert, Typography} from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import {useKeycloak} from "@react-keycloak/web";
import {useNavigate} from 'react-router-dom';
import CurrentTime from "../Layout/CurrentTime";

// Chart configuration generator
const createChartOptions = (data, categories, gradientStart, gradientEnd) => ({
    chart: {
        type: 'line',
        height: 300,
    },
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: true,
        buttons: {
            contextButton: {
                menuItems: [
                    'viewFullscreen',
                    'downloadPNG',
                    'downloadJPEG',
                    'downloadPDF',
                    'downloadSVG',
                    'separator',
                    'downloadCSV',
                    'downloadXLS',
                ],
            },
        },
    },
    xAxis: {
        categories: categories,
        lineColor: '#E5E7EB',
        tickColor: '#E5E7EB',
    },
    yAxis: {
        title: {
            text: ``,
        },
        gridLineColor: '#E5E7EB',
    },
    tooltip: {
        valueSuffix: ' kWh',
    },
    plotOptions: {
        line: {
            marker: {
                enabled: false,
            },
        },
        series: {
            states: {
                hover: {
                    lineWidth: 3,
                },
            },
        },
    },
    series: [
        {
            data: data,
            lineWidth: 3,
            color: {
                linearGradient: {x1: 0, y1: 0, x2: 1, y2: 0},
                stops: [
                    [0, gradientStart],
                    [1, gradientEnd],
                ],
            },
        },
    ],
    legend: {
        enabled: false,
    },
});

const chartBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '16px',
    width: '100%',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
};

const RealTimeMonitoring = () => {
    const {keycloak, initialized} = useKeycloak();
    const [allowed, setAllowed] = useState(false);
    const navigate = useNavigate();

    const [timeIntervals, setTimeIntervals] = useState([]);
    const [consumptionValues, setConsumptionValues] = useState([]);
    const [productionValues, setProductionValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!initialized) return;

        if (keycloak.authenticated) {
            setAllowed(true);
        } else {
            navigate('/');
        }
    }, [keycloak.authenticated, initialized, navigate]);

    useEffect(() => {
        const fetchData = () => {
            setError(false);

            axios
                .get('/aggregator/consumption/lasthour')
                .then((response) => {
                    setConsumptionValues(response.data.values);
                    setTimeIntervals(response.data.labels);
                })
                .catch(() => {
                    console.error("Error fetching consumption data");
                    setError(true);
                });

            axios
                .get('/aggregator/production/lasthour')
                .then((response) => {
                    setProductionValues(response.data.values);
                })
                .catch(() => {
                    console.error("Error fetching production data");
                    setError(true);
                })
                .finally(() => setLoading(false));
        };

        fetchData();
        const interval = setInterval(fetchData, 300000);

        return () => clearInterval(interval);
    }, []);

    const consumptionOptions = createChartOptions(
        consumptionValues,
        timeIntervals,
        '#8FCF4F',
        '#FFA726'
    );

    const productionOptions = createChartOptions(
        productionValues,
        timeIntervals,
        '#FFA726',
        '#FF6F61'
    );

    return (
        <>
            {allowed && (
                <Box sx={{padding: 3}}>
                    <CurrentTime/>
                    <Container maxWidth="xl" sx={{padding: 3}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper style={chartBoxStyle}>
                                    <Typography
                                        variant="h6"
                                        style={{textAlign: 'left', fontWeight: 'bold', color: '#4B5563'}}
                                    >
                                        Live View (Consumption)
                                    </Typography>
                                    {loading ? (
                                        <Skeleton
                                            variant="rectangular"
                                            height={250}
                                            animation="wave"
                                        />
                                    ) : error ? (
                                        <Alert
                                            severity="warning"
                                            style={{textAlign: 'center', marginTop: '20px'}}
                                        >
                                            Failed to load consumption data. Please try again later.
                                        </Alert>
                                    ) : (
                                        <HighchartsReact highcharts={Highcharts} options={consumptionOptions}/>
                                    )}
                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                <Paper style={chartBoxStyle}>
                                    <Typography
                                        variant="h6"
                                        style={{textAlign: 'left', fontWeight: 'bold', color: '#4B5563'}}
                                    >
                                        Live View (Production)
                                    </Typography>
                                    {loading ? (
                                        <Skeleton
                                            variant="rectangular"
                                            height={250}
                                            animation="wave"
                                        />
                                    ) : error ? (
                                        <Alert
                                            severity="warning"
                                            style={{textAlign: 'center', marginTop: '20px'}}
                                        >
                                            Failed to load production data. Please try again later.
                                        </Alert>
                                    ) : (
                                        <HighchartsReact highcharts={Highcharts} options={productionOptions}/>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            )}
        </>
    );
};

export default RealTimeMonitoring;
