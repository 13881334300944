import {Route, Routes} from 'react-router-dom';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import axios from "axios";

import Homepage from "./pages/Homepage";
import Layout from "./Layout/Layout";
import Overview from "./pages/Overview";
import RealTimeMonitoring from "./pages/RealTimeMonitoring";
import HistoricalStatistics from "./pages/HistoricalStatistics";
import Forecasts from "./pages/Forecasts";
import NotFound from "./pages/NotFound";

// Dashboard theme setup here
// You can set your colors in order to be reusable
const theme = createTheme({
    palette: {
        primary: {
            main: '#161F6D',
        },
        secondary: {
            main: '#00ABE1',
        },
        gradient: {
            main: `linear-gradient(to right, #161F6D, #00ABE1)`
        },
        fancyInverted: {
            main: `linear-gradient(to right, #00ABE1, #161F6D)`
        },
        middle: {
            main: '#0A6BAC'
        }
    },
    typography: {
        fontFamily: [
            'Poppins',
            'Segoe UI',
            'Roboto',
            'Jura'
        ].join(','),
    }
});

function App() {
    // Including here any configuration for axios
    // Set here the base url of the backend - if applicable
    // axios.defaults.baseURL = 'http://hedgeiot.epu.ntua.gr:31310';
    axios.defaults.baseURL = 'https://hedgeiot.epu.ntua.gr/api';
    // Set here any default headers - in the line below, the access token of Keycloak is inserted in each axios REST API call
    // axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}` || '';
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Routes>
                    <Route path="/" element={<Homepage/>}/>
                    <Route path="/overview" element={<Overview/>}/>
                    <Route path="/monitoring" element={<RealTimeMonitoring/>}/>
                    <Route path="/historical" element={<HistoricalStatistics/>}/>
                    <Route path="/forecasts" element={<Forecasts/>}/>
                    {/* Catch-all route for non-existent pages */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Layout>
        </ThemeProvider>
    );
}

export default App;
