import React, {useState, useEffect} from 'react';
import {Grid, Paper, Typography, Box, Alert, Skeleton} from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import {useKeycloak} from "@react-keycloak/web";
import { useNavigate } from 'react-router-dom';
import CurrentTime from "../Layout/CurrentTime";

// Pie chart configuration
const chartOptions = {
    chart: {
        type: 'pie',
        height: 250,
        backgroundColor: '#F9FAFB',
    },
    credits: {
        enabled: false,
    },
    title: {
        text: 'Number of Users',
        align: 'left',
        // verticalAlign: 'middle',
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
    },
    tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>',
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            innerSize: '60%',
            borderWidth: 0,
            dataLabels: {
                enabled: false,
            },
            showInLegend: true,
        },
    },
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemStyle: {
            color: '',
            fontWeight: '500',
        },
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: 5,
    },
    series: [
        {
            name: 'Users',
            colorByPoint: true,
            data: [
                {name: 'Consumers', y: 40, color: '#1E90FF'},
                {name: 'Community members', y: 35, color: '#8A2BE2'},
                {name: 'Prosumers', y: 25, color: '#FFA500'},
            ],
        },
    ],
};

const Overview = () => {
    const {keycloak, initialized} = useKeycloak()
    const [allowed, setAllowed] = useState(false)
    const navigate = useNavigate();

    const [consumption, setConsumption] = useState(0);
    const [production, setProduction] = useState(0);
    const [netEnergyBalance, setNetEnergyBalance] = useState(0);
    const [netEnergyPercentage, setNetEnergyPercentage] = useState(0);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!initialized) return; // Wait until Keycloak is initialized

        if (keycloak.authenticated) {
            setAllowed(true); // User is authenticated, allow access
        } else {
            navigate('/'); // User is not authenticated, redirect to homepage
        }
    }, [keycloak.authenticated, initialized, navigate]);

    useEffect(() => {
        axios
            .get('/aggregator/totals')
            .then((response) => {
                const {consumption, production} = response.data;

                const consumptionValue = parseFloat(consumption);
                const productionValue = parseFloat(production);
                const balance = productionValue - consumptionValue;

                setConsumption(consumptionValue);
                setProduction(productionValue);
                setNetEnergyBalance(balance);

                // Calculate the percentage change relative to consumption
                const percentageChange = (balance / consumptionValue) * 100;
                setNetEnergyPercentage(percentageChange);
                setError(false); // Reset error on success
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setError(true); // Set error state
            })
            .finally(() => setLoading(false)); // Disable loading state
    }, []);

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '16px',
        width: '100%',
        height: '140px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    };

    const metricBox = (title, value, description, percentage = null) => (
        <Paper elevation={0} style={boxStyle}>
            {loading ? (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height="100%"
                    style={{borderRadius: '16px'}}
                />
            ) : (
                <>
                    {error && <Alert severity="warning">Error fetching data</Alert>}
                    {!error && (
                        <>
                            <Typography variant="h6">{title}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {description}
                            </Typography>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h5" style={{fontWeight: 'bold'}}>
                                    {value.toFixed(2)} kWh
                                </Typography>
                                {percentage !== null && (
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: percentage >= 0 ? 'green' : 'red',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        {percentage >= 0 ? '+' : ''}
                                        {percentage.toFixed(2)}%
                                    </Typography>
                                )}
                            </Box>
                        </>
                    )}
                </>
            )}
        </Paper>
    );

    return (
        <>
            {allowed && <Box sx={{padding: 3}}>
                <CurrentTime/>

                <Grid container spacing={3}>
                    {/* Left Column: Two Boxes */}
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {metricBox(
                                    'Total Demand',
                                    consumption,
                                    'Total energy consumption of all consumers within the network'
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {metricBox(
                                    'Total Production',
                                    production,
                                    'Total energy production from all PVs'
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Right Column: Pie Chart */}
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={0}
                            style={{
                                ...boxStyle,
                                padding: '30px',
                                height: '300px', // Match the combined height of two boxes
                                textAlign: 'center',
                            }}
                        >
                            {/*<Typography*/}
                            {/*    variant="h6"*/}
                            {/*    style={{ fontWeight: 'bold' }}*/}
                            {/*>*/}
                            {/*    Number of Users*/}
                            {/*</Typography>*/}
                            <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
                        </Paper>
                    </Grid>

                    {/* Third Box */}
                    <Grid item xs={12} md={6}>
                        {metricBox(
                            'Net Energy Balance',
                            netEnergyBalance,
                            'Difference between total production and total demand',
                            netEnergyPercentage
                        )}
                    </Grid>
                </Grid>
            </Box>}
        </>
    );
};

export default Overview;
