import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import {useKeycloak} from "@react-keycloak/web";
import {useLocation, useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import HistoryIcon from '@mui/icons-material/History';
import UpdateIcon from '@mui/icons-material/Update';
import LoginIcon from '@mui/icons-material/Login';

import {Main, AppBar, DrawerHeader, Footer} from '../components/LayoutComponents';
import FooterContent from '../components/FooterContent';
import MenuButton from "./MenuButton";

import {appbarMenuButtonItems} from "./appbarMenuButtonItems";

const drawerWidth = 275;

export default function Layout({children}) {
    const {keycloak, initialized} = useKeycloak();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate()

    // The functions below handle the Drawer open/close
    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    // Group your desired navigation items and their details here
    // You can apply checks and optional rendering based on authentication status, roles, etc. with custom functions (using the Keycloak instance)
    const [navItems, setNavItems] = useState([
        {
            title: 'Homepage',
            icon: <HomeIcon sx={{color: theme.palette.primary.main}}/>,
            path: '/'
        },
        {
            title: 'Overview',
            icon: <DashboardIcon sx={{color: theme.palette.primary.main}}/>,
            path: '/overview'
        },
        {
            title: 'Real-Time Monitoring',
            icon: <SsidChartIcon sx={{color: theme.palette.primary.main}}/>,
            path: '/monitoring'
        },
        {
            title: 'Historical Statistics',
            icon: <HistoryIcon sx={{color: theme.palette.primary.main}}/>,
            path: '/historical'
        },
        {
            title: 'Forecasts',
            icon: <UpdateIcon sx={{color: theme.palette.primary.main}}/>,
            path: '/forecasts'
        },
    ])

    useEffect(() => {
        if (keycloak.authenticated) {
            setNavItems([
                {
                    title: 'Homepage',
                    icon: <HomeIcon sx={{color: theme.palette.primary.main}}/>,
                    path: '/'
                },
                {
                    title: 'Overview',
                    icon: <DashboardIcon sx={{color: theme.palette.primary.main}}/>,
                    path: '/overview'
                },
                {
                    title: 'Real-Time Monitoring',
                    icon: <SsidChartIcon sx={{color: theme.palette.primary.main}}/>,
                    path: '/monitoring'
                },
                {
                    title: 'Historical Statistics',
                    icon: <HistoryIcon sx={{color: theme.palette.primary.main}}/>,
                    path: '/historical'
                },
                {
                    title: 'Forecasts',
                    icon: <UpdateIcon sx={{color: theme.palette.primary.main}}/>,
                    path: '/forecasts'
                },
            ])
        } else {
            setNavItems([
                {
                    title: 'Homepage',
                    icon: <HomeIcon sx={{color: theme.palette.primary.main}}/>,
                    path: '/'
                },
                {
                    title: 'Sign In',
                    icon: <LoginIcon sx={{color: theme.palette.primary.main}}/>,
                    onClick: () => keycloak.login(),
                    path: ''
                },
            ])
        }
    }, [keycloak, initialized])

    const handleSignOut = () => {
        keycloak.logout({
            redirectUri: `${window.location.origin}/`, // Redirect to the home page
        });
    };


    return (
        <>
            <Box sx={{display: 'flex', minHeight: `calc(100vh - 60px)`}}>
                <CssBaseline/>
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <Grid container alignItems="center">
                            <Grid item
                                  // sx={{display: {xs: "none", lg: "block"}}}
                            >
                                <Stack direction={'row'} alignItems="center">
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        sx={{mr: 2, color: 'white', ...(open && {display: 'none'})}}
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                    <Typography color={'white'} variant={'h5'} fontWeight={600}>Energy Aggregator Dashboard</Typography>
                                </Stack>
                            </Grid>

                            {keycloak.authenticated === true && <>
                                <Typography
                                    sx={{ml: 'auto'}}
                                    style={{
                                        color: 'white'
                                    }}>Welcome, {keycloak?.tokenParsed?.preferred_username}</Typography>
                                <MenuButton subLinks={appbarMenuButtonItems} signout={handleSignOut}/>
                            </>}
                        </Grid>

                    </Toolbar>
                </AppBar>


                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                        </IconButton>
                    </DrawerHeader>
                    <Divider/>
                    <List>
                        {navItems.map(menuItem => (
                            <ListItem
                                key={menuItem.title}
                                disablePadding
                                sx={{
                                    background: location.pathname === menuItem.path ? theme.palette.gradient.main : '',
                                    border: location.pathname === menuItem.path ? '1px solid rgba(22, 31, 109, 0.5)' : '',
                                    borderRadius: '10px',
                                    margin: 1,
                                    width: '95%'
                                }}
                            >
                                <ListItemButton
                                    onClick={() => {
                                        if (menuItem.onClick) {
                                            menuItem.onClick(); // Call the custom onClick if it exists
                                        } else if (menuItem.path) {
                                            navigate(menuItem.path); // Navigate if a path is defined
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        {React.cloneElement(menuItem.icon, {
                                            sx: {
                                                color: location.pathname === menuItem.path ? 'white' : theme.palette.primary.main
                                            }
                                        })}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                fontWeight={500}
                                                fontSize={17}
                                                align={'left'}
                                                color={location.pathname === menuItem.path ? 'white' : 'normal'}
                                            >
                                                {menuItem.title}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>

                    <Divider/>
                </Drawer>
                <Main open={open} sx={{background: '#F6F7F9'}}>
                    <DrawerHeader/>
                    {children}
                </Main>
            </Box>

            <Footer open={open} sx={{position: 'sticky', mt: 'auto'}}>
                <FooterContent/>
            </Footer>
        </>
    );
}
